import React from "react"
import Guide from "../../../../components/guide2020"

const IndexPage = ({data}) => (
  <Guide
    Year={"2020"}
    Section={"bat/turbat"}
    ProductName={"turbåtforsikring"}
    Entity={"turbåt"}
    Attributes={36}
    Stars5={{
      need: "For deg som har en ny båt og vil være aller best sikret",
      description: 'De aller beste båtforsikringene - 5 defaqto stjerner',
      terms: [
        {t: "Personlig eiendeler maksimal sum",c: "Må være dekket for 20 000 kr"},
        {t: "Ansvar personskade",c: "Må være dekket for 10 MNOK"},
        {t: "Personlig ansvar ting",c: "Må være dekket for 8 MNOK"},
        {t: "Transport til reparasjon / verksted",c: "Må være dekket"},
        {t: "Båtredning - slepekostnader",c: "Må være dekket"},
        {t: "Totalskadegaranti - alder ny båt",c: "Må være dekket i 3 år"},
        {t: "Maskinskader er dekket",c: "Må være dekket i 3 år"},
      ]
    }}
    Stars4={{
      need: "For deg som har en fritidsbåt, men ikke behov for de høyeste dekningene",
      description: 'Veldig gode båtforsikringer - 4 defaqto stjerner',
      terms: [
        {t: "Personlig eiendeler maksimal sum",c: "Må være dekket for 10 000 kr"},
        {t: "Ansvar personskade",c: "Må være dekket for 10 MNOK"},
        {t: "Personlig ansvar ting",c: "Må være dekket for 8 MNOK"},
        {t: "Transport til reparasjon / verksted",c: "Må være dekket"},
        {t: "Båtredning - slepekostnader",c: "Må være dekket"},
      ]
    }}
    Stars3={{
      need: "For deg som har en noe eldre båt",
      description: 'Gode båtforsikringer - 3 defaqto stjerner',
      terms: [
      ]
    }}
  />
)

export default IndexPage